@import '../../styles/common';
$break-small: 600px;
$break-middle: 800px;
$break-large: 1000px;


// Mobile : width <= 800
@media only screen and (max-width: $break-small) {
  .order {
    .body {
  
      text-align: center;
      .title {
        margin: auto;
        @include title_page;
        padding-top: 80px; // because it is different from about page
      }
      .box {
  
        text-align: center;
  
        .title {
          @include title_road;
          font-size: 22px;
          width: 800px;
          margin: 60px auto;
          max-width: 90%;
        }
        .text {
          width: 500px;
          margin: auto;
          text-align: left;
          line-height: 1.5em;
          max-width: 85%;
          font-size: 10px;
        }
        .image1 {
          padding-top: 50px;
          img {
            width: 800px;
            max-width: 100%;
          }
        }
        .image2 {
          margin-bottom: 70px;
          img {
            width: 800px;
            max-width: 100%;
            
          }
        }
  
      }
    }
  }
  

  
}

// Web : width > 800
@media only screen and (min-width: $break-small + 1) {
  .order {
    .body {
  
      text-align: center;
      .title {
        margin: auto;
        @include title_page;
        padding-top: 80px; // because it is different from about page
      }
      .box {
  
        text-align: center;
  
        .title {
          @include title_road;
          font-size: 22px;
          width: 800px;
          margin: 60px auto;
          max-width: 90%;
  
        }
        .text {
          width: 500px;
          margin: auto;
          text-align: left;
          line-height: 1.5em;
          max-width: 85%;
        }
        .image1 {
          padding-top: 50px;
          img {
            width: 800px;
          }
        }
        .image2 {
          margin-bottom: 70px;
          
          img {
            width: 800px;
            
          }
        }
  
      }
    }
  }
  

  

}




// All common style attribute in here
$titleFontsize: 40px;
$contentFontsize: 16px;

@mixin title_page {
  font-size: $titleFontsize;
  font-weight: bold;
  padding-top: 50px;
  padding-bottom: 50px;
}

@mixin block {
  width: 250px;
  height: 7px;
  background-color: gray;
  margin: 10px auto;
}

@mixin title_road {
  padding: 10px;
  margin: 20px;
  border-top: solid 2px black;
  border-bottom: solid 2px black;
}
